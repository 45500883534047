class Category {
    constructor({ id, type, shapes, name = '', customCover = false }) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.shapes = shapes;
        this.customCover = customCover;
        this.selectedShape = shapes ? Number(Object.keys(shapes)[0]) : 0;
        this.selectedSlices = shapes ? shapes[this.selectedShape].slices[0] : 0;
        this.products = [];
    }

    addProduct(product) {
        if (!this.products.includes(product)) {
            this.products.push(product);
        }
    }
}
export default Category;
