<template>
    <div>
        <div class="page-header clear-filter" filter-color="orange">
            <parallax class="page-header-image" style="background-image: url('img/bg_gallery.jpg')"></parallax>
            <div class="container">
                <img src="img/logo.png" class="img-fluid" style="max-height: 300px" />
                <h3 class="title">Galeria de fotos</h3>
            </div>
        </div>
        <div class="section bg-primary">
            <div class="container">
                <div class="row">
                    <CoolLightBox :items="items" :index="index" @close="index = null"> </CoolLightBox>

                    <div
                        class="col-lg-4 col-md-6 p-3 pb-4"
                        v-for="(image, imageIndex) in items"
                        :key="imageIndex"
                        @click="index = imageIndex"
                    >
                        <img :src="image.src" class="img-fluid" style="cursor: pointer" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Tabs, TabPane } from '@/components';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
    name: 'gallery',
    bodyClass: 'profile-page',
    data() {
        return {
            index: null,
            items: [
                { src: 'img/gallery/1.jpg' },
                { src: 'img/gallery/2.jpg' },
                { src: 'img/gallery/3.jpg' },
                { src: 'img/gallery/4.jpg' },
                { src: 'img/gallery/5.jpg' },
                { src: 'img/gallery/6.jpg' },
                { src: 'img/gallery/7.jpg' },
                { src: 'img/gallery/8.jpg' },
                { src: 'img/gallery/9.jpg' },
                { src: 'img/gallery/10.jpg' },
                { src: 'img/gallery/11.jpg' },
                { src: 'img/gallery/12.jpg' },
                { src: 'img/gallery/13.jpg' },
                { src: 'img/gallery/14.jpg' },
                { src: 'img/gallery/15.jpg' },
                { src: 'img/gallery/16.jpg' },
                { src: 'img/gallery/17.jpg' },
                { src: 'img/gallery/18.jpg' },
                { src: 'img/gallery/19.jpg' },
                { src: 'img/gallery/20.jpg' },
                { src: 'img/gallery/21.jpg' },
                { src: 'img/gallery/22.jpg' },
                { src: 'img/gallery/23.jpg' },
                { src: 'img/gallery/24.jpg' },
                { src: 'img/gallery/25.jpg' },
                { src: 'img/gallery/26.jpg' },
                { src: 'img/gallery/27.jpg' },
                { src: 'img/gallery/28.jpg' },
                { src: 'img/gallery/29.jpg' },
                { src: 'img/gallery/30.jpg' },
                { src: 'img/gallery/31.jpg' },
                { src: 'img/gallery/32.jpg' },
                { src: 'img/gallery/33.jpg' },
                { src: 'img/gallery/34.jpg' },
                { src: 'img/gallery/35.jpg' },
                { src: 'img/gallery/36.jpg' },
                { src: 'img/gallery/37.jpg' },
                { src: 'img/gallery/38.jpg' },
                { src: 'img/gallery/39.jpg' },
            ],
        };
    },
    components: {
        CoolLightBox,
        Tabs,
        TabPane,
    },
};
</script>
