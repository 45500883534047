let id = 0;

class Item {
    constructor({ product, flavor, shape, slices, options }) {
        this.product = product;
        this.flavor = flavor;
        this.shape = shape;
        this.slices = slices;
        this.options = options;
        this.qty = 0;
        this.id = id++;
    }

    get total() {
        return this.price * this.qty;
    }

    get price() {
        if (this.flavor) {
            return this.flavor.price;
        }
        if (this.shape && this.slices) {
            return this.product.shape[this.shape].slices[this.slices];
        }
        return this.product.price;
    }

    get fullName() {
        let name = this.product.fullName;
        if (this.flavor) {
            name += ` - ${this.flavor.name}`;
        }
        if (this.shape && this.slices) {
            name += ` ${this.product.category.shapes[this.shape].name}, ${this.slices} fatias`;
        }
        return name;
    }
}
export default Item;
