<template>
    <div>
        <div class="page-header clear-filter" filter-color="orange">
            <parallax class="page-header-image" style="background-image: url('img/bg_contact.jpg')"></parallax>
            <div class="container">
                <img src="img/logo.png" class="img-fluid" style="max-height: 300px" />
                <h3 class="title">Contato</h3>
            </div>
        </div>
        <div class="section bg-primary">
            <div class="container">
                <h3 class="title">Horários de atendimento</h3>
                <h5 class="description">
                    <p>Segundas: 13:00 às 18:00</p>
                    <p>Terças à Sextas: 09:00 às 11:30 | 13:00 às 18:00</p>
                    <p>Sábados: 09:00 às 12:00 | 13:00 às 15:00</p>
                    <p>Domingos e Feriados: FECHADO</p>
                    <p>Para retirada fora do horário de atendimento, somente mediante agendamento prévio</p>
                </h5>
            </div>
            <div class="container">
                <h3 class="title">Encontre-nos</h3>
                <h5 class="description">
                    Este é nosso endereço para retirada do seu pedido. Caso deseje nos visitar, por favor agende através do nosso
                    WhatsApp.
                </h5>
                <div class="row">
                    <div class="container-fluid">
                        <div class="map-responsive">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7215.798407434086!2d-51.82693758022011!3d-29.47210571145001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951c6fbb8353edeb%3A0xac4867133072648f!2sDel%C3%ADcias%20da%20Rose!5e0!3m2!1spt-BR!2sbr!4v1583195656426!5m2!1spt-BR!2sbr"
                                width="100%"
                                height="100%"
                                frameborder="0"
                                style="border: 0"
                                allowfullscreen=""
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Tabs, TabPane } from '@/components';

export default {
    name: 'contact',
    bodyClass: 'profile-page',
    components: {
        Tabs,
        TabPane,
    },
};
</script>
