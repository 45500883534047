import filters from '@/directives/filters';
import template from './ProductDesc.html';
import ProductForm from './ProductForm';
import ProductActions from './ProductActions';
import OrderController from '../../controllers/OrderController';

const status = { CLOSE: 0, OPEN: 1 };

export default {
    filters,
    template,
    props: ['product', 'type', 'shape', 'slices'],
    components: { ProductForm, ProductActions },
    data() {
        return {
            orderStatus: !this.product.flavors || this.product.flavors.flavors.length <= 2 ? 1 : 0,
        };
    },
    computed: {
        isFormVisible() {
            return this.isOrderEnable && this.orderStatus;
        },
        isOrderEnable() {
            return ENV.enableOrders;
        },
        status() {
            return status;
        },
        isVisible() {
            if (this.type.id === 3) {
                return this.shape in this.product.shape;
            }
            return true;
        },
        shapeName() {
            return this.product.category.shapes[this.shape].name;
        },
    },
    methods: {
        show() {
            this.orderStatus = 1;
        },
        hide() {
            this.orderStatus = 0;
        },
    },
};
