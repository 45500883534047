<template>
    <div>
        <div class="section bg-primary">
            <div class="container">
                <h3 class="title">Faça do seu evento um delicioso momento!</h3>
                <h5 class="description">
                    Nossos produtos são elaborados de maneira artesanal, com a finalidade de garantir o sabor e a qualidade. Nosso
                    objetivo também é estar ao seu lado em todos os momentos da sua vida, nas comemorações especiais, no trabalho,
                    em um lanche rápido, com o mesmo carinho que produzimos cada item do nosso cardápio.
                </h5>
                <div class="row">
                    <nav-link to="sweets" class="col-sm-6 col-md p-0">
                        <div class="border-0 rounded-0 shadow-none product-card">
                            <img src="img/bg_sweets.jpg" class="border-0 rounded-0 shadow-none card-img" />
                            <div class="nav-link p-0 m-0 text-center">
                                <h3 class="title">Doces</h3>
                            </div>
                        </div>
                    </nav-link>
                    <nav-link to="savories" class="col-sm-6 col-md p-0">
                        <div class="border-0 rounded-0 shadow-none product-card">
                            <img src="img/bg_savories.jpg" class="border-0 rounded-0 shadow-none card-img" />
                            <div class="nav-link p-0 m-0 text-center">
                                <h3 class="title">Salgados</h3>
                            </div>
                        </div>
                    </nav-link>
                    <nav-link to="cakes" class="col-sm-6 col-md p-0">
                        <div class="border-0 rounded-0 shadow-none product-card">
                            <img src="img/bg_cakes.jpg" class="border-0 rounded-0 shadow-none card-img" />
                            <div class="nav-link p-0 m-0 text-center">
                                <h3 class="title">Tortas</h3>
                            </div>
                        </div>
                    </nav-link>
                    <nav-link to="combos" class="col-sm-6 col-md p-0">
                        <div class="border-0 rounded-0 shadow-none product-card">
                            <img src="img/bg_combos_front.jpg" class="border-0 rounded-0 shadow-none card-img" />
                            <div class="nav-link p-0 m-0 text-center">
                                <h3 class="title">Combos</h3>
                            </div>
                        </div>
                    </nav-link>
                    <nav-link to="deserts" class="col-sm-6 col-md p-0">
                        <div class="border-0 rounded-0 shadow-none product-card">
                            <img src="img/bg_deserts.jpg" class="border-0 rounded-0 shadow-none card-img" />
                            <div class="nav-link p-0 m-0 text-center">
                                <h3 class="title">Sobremesas</h3>
                            </div>
                        </div>
                    </nav-link>
                </div>
                <div class="row text-center">
                    <h3 class="title">Trabalhamos somente sob encomenda!</h3>
                    <h5 class="description">Os valores podem sofrer alterações sem aviso prévio.</h5>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { NavLink } from '@/components';
export default {
    name: 'products-index',
    bodyClass: 'products-page',
    components: { NavLink },
};
</script>
