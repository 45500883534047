class Flavors {
    constructor(product, flavors) {
        this.product = product;
        this.flavors = this.processFlavors(flavors);
        this.groups = this.processGroups();
    }

    get all() {
        return this.flavors;
    }

    processFlavors(data) {
        return data.map(([name, price], id) => {
            return { id, name, price };
        });
    }

    processGroups() {
        const result = {};
        this.flavors.forEach((flavor) => {
            if (!result[flavor.price]) {
                result[flavor.price] = {
                    items: [],
                    get resume() {
                        return this.items.reduce((prev, cur) => {
                            return prev ? `${prev} | ${cur.name}` : `${cur.name}`;
                        }, '');
                    },
                };
            }
            result[flavor.price].items.push(flavor);
        });
        return result;
    }
}

export default Flavors;
