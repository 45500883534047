<template>
	<component :is="componentType" :tag="tagName" :to="{ name: to }" @click.native.stop="closeNav">
		<slot></slot>
	</component>
</template>
<script>
export default {
	name: 'nav-link',
	props: {
		tag: { type: [String, Object], default: undefined }, 
		to: { type: [String, Object], default: undefined }
	},
	computed: {
		tagName(){
			return this.tag ? this.tag : undefined;
		},
		componentType() {
			return this.to ? 'router-link' : 'a';
		}
	},
	methods: {
		closeNav() {
			this.$parent.close();
		}
	}
};
</script>
<style></style>
