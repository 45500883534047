<template>
    <div>
        <div class="page-header clear-filter" filter-color="orange">
            <parallax class="page-header-image" style="background-image: url('img/mainBackground.jpg')"></parallax>
            <div class="container">
                <div class="content-center brand">
                    <img class="n-logo" src="img/logo.png" />
                </div>
                <h6 class="category category-absolute">
                    Desenvolvido por <a href="https://www.instagram.com/markitandafotos/" target="_blank">Markitanda</a>
                </h6>
            </div>
        </div>
        <products-index></products-index>
    </div>
</template>
<script>
import { Parallax } from '@/components';
import ProductsIndex from './ProductsIndex';

export default {
    name: 'index',
    bodyClass: 'index-page',
    components: {
        Parallax,
        ProductsIndex,
    },
};
</script>
