<template>
    <div>
        <div class="page-header clear-filter" filter-color="orange">
            <parallax class="page-header-image" style="background-image: url('img/bg_gallery.jpg')"></parallax>
            <div class="container">
                <img src="/img/logo.png" class="img-fluid" style="max-height: 300px" />
                <h3 class="title">Pedido</h3>
            </div>
        </div>
        <div class="section bg-primary">
            <div class="container">
                <h3 class="title" v-if="sent">
                    Pedido finalizado, prossiga com o envio pelo WhatsApp e aguarde a confirmação da nossa equipe!
                </h3>
                <h3 class="title" v-else-if="items.length">Confirme seu pedido para envio</h3>
                <h3 class="title" v-else>Nenhum item no pedido</h3>
                <div class="row justify-content-md-center">
                    <div class="col col-sm-11 col-lg-10 order-container">
                        <div class="row justify-content-left p-3 checkout-item" v-for="(item, index) in items" :key="item.id">
                            <div class="col-auto remove-col"><div class="remove-btn" @click="removeItem(index)">X</div></div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-8 col-sm-9 col-lg-10">
                                        {{ item.qty }}{{ item.product.unity }} {{ item.fullName }}
                                        <small v-if="item.options">({{ item.options.map((opt) => opt.desc).join(', ') }})</small>
                                    </div>
                                    <div class="col-4 col-sm-3 col-lg-2 price-col">{{ item.total | currency }}</div>
                                    <div class="col-12 desc-col" v-if="item.desc">
                                        {{ item.desc }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center" v-if="items.length">
                    <div class="col col-sm-11 col-lg-10 order-separator order-container">
                        <div class="row justify-content-left p-3">
                            <div class="col">
                                <div class="row">
                                    <div class="col-8 col-sm-9 col-lg-10"><h4>Total</h4></div>
                                    <div class="col-4 col-sm-3 col-lg-2 price-col">{{ total | currency }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-md-center" v-if="items.length">
                    <div class="col col-sm-11 col-lg-10 order-container order-container-date p-4">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-7 p-2">Informe seu nome:</div>
                            <div class="col col-md-8 col-lg-5 price-col">
                                <input type="text" v-model="name" class="input-text" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center" v-if="items.length">
                    <div class="col col-sm-11 col-lg-10 order-container order-container-date p-4">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-7 p-2">Data e hora para retirada do pedido:</div>
                            <div class="col col-md-8 col-lg-5 price-col">
                                <date-picker
                                    v-model="orderDate"
                                    :default-value="new Date()"
                                    :disabled-date="disabledDate"
                                ></date-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center" v-if="items.length && showDateInvalid">
                    <div class="col col-sm-11 col-lg-10 order-container order-info p-4">
                        <span class="text-danger fs-6">
                            * ATENÇÃO! Devido à alta demanda, não estamos aceitando pedidos de tortas para esta data, apenas os
                            demais produtos.
                        </span>
                    </div>
                </div>
                <div class="row justify-content-md-center" v-if="items.length && datePeriods.length">
                    <div class="col col-sm-11 col-lg-10 order-container order-container-date p-4">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-7 p-2">Período para retirada do pedido:</div>
                            <div class="col col-md-8 col-lg-5 price-col">
                                <div class="selector">
                                    <div
                                        class="selector-btn"
                                        :class="{ selected: selectedPeriod == key }"
                                        v-for="(period, key) in datePeriods"
                                        :key="key"
                                        @click="selectPeriod(key)"
                                    >
                                        {{ period }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center" v-if="items.length">
                    <div class="col col-sm-11 col-lg-10 order-info p-4">
                        <span v-if="hasCustomCoverItems">
                            * Para coberturas personalizadas, informe no WhatsApp logo após enviar o pedido!
                            <br />
                        </span>
                        <span v-if="showDateInfo">
                            * Trabalhamos por encomenda! Para podermos garantir a disponibilidade de todos os produtos, é
                            recomendado 2 dias ou mais de antecedência no pedido.
                            <br />
                        </span>
                    </div>
                </div>
                <div class="row justify-content-md-center pt-4" v-if="items.length">
                    <div class="btn p-3" @click="sendOrder()" :class="{ disable: !name && !orderDate }">Enviar Pedido</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OrderController from '../controllers/OrderController';
import filters from '@/directives/filters';
import DatePicker from 'vue2-datepicker';
import datesConfig from '../data/dates.json';

const { dates, periods, auxPeriods, specialPeriods } = datesConfig;

export default {
    bodyClass: 'profile-page',
    components: { DatePicker },
    filters,
    data() {
        return {
            items: OrderController.productList,
            minDate: null,
            maxDate: null,
            today: null,
            forbiddenDates: [],
            orderDate: null,
            name: '',
            selectedPeriod: 0,
            periods,
            auxPeriods,
            dates,
            sent: false,
        };
    },
    computed: {
        datePeriods() {
            if (!this.orderDate) {
                return [];
            }
            const auxDate = this.orderDate.toISOString().split('T')[0];
            if (specialPeriods[auxDate]) {
                return specialPeriods[auxDate];
            }

            if (this.orderDate.getFullYear() === 2024 && this.orderDate.getMonth() < 2) {
                return this.auxPeriods[this.orderDate.getDay()];
            }

            return this.periods[this.orderDate.getDay()];
        },
        textPeriod() {
            if (!this.orderDate) {
                return '';
            }

            const auxDate = this.orderDate.toISOString().split('T')[0];
            if (specialPeriods[auxDate]) {
                return specialPeriods[auxDate][this.selectedPeriod];
            }

            if (this.orderDate.getFullYear() === 2024 && this.orderDate.getMonth() < 2) {
                return this.auxPeriods[this.orderDate.getDay()][this.selectedPeriod];
            }
            return this.periods[this.orderDate.getDay()][this.selectedPeriod];
        },
        hasCustomCoverItems() {
            return this.items.some((item) => item.product.category.customCover);
        },
        showDateInfo() {
            const minPeriod = 24 * 3600 * 1000 * 3; // 3 dias
            return this.orderDate && this.orderDate.getTime() < this.today.getTime() + minPeriod;
        },
        total() {
            return OrderController.total;
        },
        showDateInvalid() {
            if (!this.orderDate) return false;
            const currentDate = this.orderDate.toISOString().split('T')[0];
            const cakeDates = this.dates.cakeForbidden.map((d) => d.split(' ')[0]);
            return OrderController.hasCakes() && cakeDates.includes(currentDate);
        },
    },
    mounted() {
        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);
        this.minDate = new Date(this.today.getTime() + this.dates.min * 24 * 3600 * 1000);
        this.maxDate = new Date(this.today.getTime() + this.dates.max * 24 * 3600 * 1000);
        this.dates.forbidden.forEach((d) => this.forbiddenDates.push(Number(new Date(d))));

        if (OrderController.isOnlyCakes()) {
            this.dates.cakeForbidden.forEach((d) => this.forbiddenDates.push(Number(new Date(d))));
        }
    },
    methods: {
        selectPeriod(nIndex) {
            this.selectedPeriod = nIndex;
        },
        removeItem(index) {
            OrderController.remove(index);
        },
        disabledDate(date) {
            return date < this.minDate || date > this.maxDate || this.forbiddenDates.includes(+date) || date.getDay() === 0;
        },
        sendOrder() {
            const text = OrderController.mountText(this.orderDate, this.textPeriod, this.name);
            this.sent = true;
            this.items = [];
            OrderController.clear();
            window.open(`https://wa.me/${ENV.wppNumber}?text=${text}`, '_blank');
        },
    },
    watch: {
        orderDate() {
            this.selectedPeriod = 0;
        },
    },
};
</script>

<style scoped>
.order-container {
    background-color: #0003;
    color: white;
    font-size: 20px;
}
.price-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.remove-col {
    display: flex;
    align-items: center;
}
.desc-col {
    color: #fff3;
    font-size: 16px;
}
.remove-btn {
    padding: 3px 10px;
    cursor: pointer;
    border: 1px solid var(--main-font-color);
    color: var(--main-font-color);
    background-color: transparent;
    user-select: none;
    font-size: 14px;
}
.remove-btn:active {
    background-color: #ffffff33;
}
.remove-btn:focus {
    outline: 0;
}
.remove-btn.disable {
    background-color: #ffffff22;
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
}
.input-text {
    background-color: transparent !important;
    color: var(--main-font-color) !important;
    border: 1px solid var(--main-font-color) !important;
    border-radius: 0 !important;
    outline: none;
    width: 100%;
}
.mx-datepicker {
    width: 100%;
}
.order-separator {
    border-top: 1px solid #dedede30;
    border-bottom: 1px solid #dedede30;
}
.order-separator h4 {
    margin: 10px 0;
}
.btn {
    margin-top: 10px;
    padding: 3px 15px;
    cursor: pointer;
    border: 1px solid var(--main-font-color);
    color: var(--main-font-color);
    background-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 200px;
}
.btn:active {
    background-color: #ffffff33;
}
.btn:hover {
    background-color: #ffffff33;
}
.btn:focus {
    outline: 0;
}
.btn.disable {
    background-color: #ffffff22;
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
}
</style>
