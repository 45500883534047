<template>
  <footer class="footer" :class="{ [`footer-${type}`]: type }" :data-background-color="backgroundColor">
    <div class="container">
      <div class="copyright">
        &copy; {{ year }}, Desenvolvido por <a href="https://www.instagram.com/markitanda1/" target="_blank" rel="noopener">Markitanda</a>.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
