<template>
    <navbar
        ref="navbar"
        position="fixed"
        type="primary"
        :transparent="transparent"
        :color-on-scroll="colorOnScroll"
        menu-classes="ml-auto"
    >
        <template>
            <router-link class="navbar-brand" to="/">Início</router-link>
        </template>
        <template slot="navbar-menu">
            <li class="nav-item">
                <nav-link to="deserts" class="nav-link"><p>Sobremesas</p></nav-link>
            </li>
            <li class="nav-item">
                <nav-link to="sweets" class="nav-link"><p>Doces</p></nav-link>
            </li>
            <li class="nav-item">
                <nav-link to="savories" class="nav-link"><p>Salgados</p></nav-link>
            </li>
            <li class="nav-item">
                <nav-link to="cakes" class="nav-link"><p>Tortas</p></nav-link>
            </li>
            <li class="nav-item">
                <nav-link to="combos" class="nav-link"><p>Combos</p></nav-link>
            </li>
            <li class="nav-item">
                <nav-link to="gallery" class="nav-link"><p>Galeria</p></nav-link>
            </li>
            <li class="nav-item">
                <nav-link to="contact" class="nav-link"><p>Contato</p></nav-link>
            </li>

            <li class="nav-item">
                <a class="nav-link" href="https://api.whatsapp.com/send?phone=5551993395523" target="_blank">
                    <i class="fab fa-whatsapp"></i>
                    <p class="d-lg-none d-xl-none">Whatsapp</p>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://www.facebook.com/deliciasdaroseteutonia" target="_blank">
                    <i class="fab fa-facebook-square"></i>
                    <p class="d-lg-none d-xl-none">Facebook</p>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://www.instagram.com/deliciasdaroseteuto/" target="_blank">
                    <i class="fab fa-instagram"></i>
                    <p class="d-lg-none d-xl-none">Instagram</p>
                </a>
            </li>
            <li class="nav-item badge-container" v-if="counter > 0">
                <nav-link to="checkout" class="nav-link">
                    <i class="fas fa-shopping-cart"></i>
                    <span class="badge badge-info">{{ counter }}</span>
                    <p class="d-lg-none d-xl-none">Pedido</p>
                </nav-link>
            </li>
        </template>
    </navbar>
</template>

<script>
import { NavbarToggleButton, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
import OrderController from '../controllers/OrderController';
export default {
    name: 'main-navbar',
    props: {
        transparent: Boolean,
        colorOnScroll: Number,
    },
    data() {
        return {
            OrderController,
        };
    },
    computed: {
        counter() {
            return this.OrderController.count;
        },
    },
    components: {
        Navbar,
        NavbarToggleButton,
        NavLink,
        [Popover.name]: Popover,
    },
};
</script>

<style scoped></style>
