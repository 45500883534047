import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/scss/app.scss';
import globalDirectives from './plugins/globalDirectives';
import globalMixins from './plugins/globalMixins';
import globalComponents from './plugins/globalComponents';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import VueLazyload from 'vue-lazyload';

locale.use(lang);

export default {
	install(Vue) {
		Vue.use(globalDirectives);
		Vue.use(globalMixins);
		Vue.use(globalComponents);
		Vue.use(VueLazyload, {
			observer: true,
			observerOptions: {
				rootMargin: '0px',
				threshold: 0.1
			}
		});
	}
};
