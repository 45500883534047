import OrderController from '../../controllers/OrderController';
import template from './ProductInput.html';

export default {
    template,
    props: ['product', 'flavor', 'slices', 'shape'],
    data() {
        return {
            item: null,
            qty: 0,
            intervalInc: 0,
            intervalDec: 0,
            timerInc: 0,
            timerDec: 0,
        };
    },
    mounted() {
        this.item = OrderController.getItem(this.product, this.flavor, this.shape, this.slices);
        this.qty = this.item ? this.item.qty : 0;
    },
    computed: {
        label() {
            return this.flavor ? this.flavor.name : 'Quantidade';
        },
    },
    methods: {
        updateItem() {
            if (!this.item) {
                this.item = OrderController.addItem(this.product, this.flavor, this.shape, this.slices);
            }
            this.item.qty = this.qty;
            if (this.item.qty === 0) {
                this.removeItem();
            }
        },
        inc() {
            const step = this.qty >= 2 * this.product.min ? this.product.step : 1;
            const newValue = this.qty + step;
            this.qty = newValue < this.product.min ? this.product.min : newValue;
            this.updateItem();
        },
        dec() {
            const step = this.qty > 2 * this.product.min ? this.product.step : 1;
            const newValue = this.qty - step;
            this.qty = newValue < this.product.min ? 0 : newValue;
            this.updateItem();
        },
        removeItem() {
            OrderController.removeItem(this.item.id);
            this.item = null;
            this.qty = 0;
        },
        releaseDec() {
            if (this.timerDec) {
                clearTimeout(this.timerDec);
                this.timerDec = 0;
            }
            if (this.intervalDec) {
                clearInterval(this.intervalDec);
                this.intervalDec = 0;
            }
        },
        holdDec() {
            this.timerDec = setTimeout(() => {
                this.intervalDec = setInterval(() => {
                    this.dec();
                }, 150);
            }, 500);
        },
        releaseInc() {
            if (this.timerInc) {
                clearTimeout(this.timerInc);
                this.timerInc = 0;
            }
            if (this.intervalInc) {
                clearInterval(this.intervalInc);
                this.intervalInc = 0;
            }
        },
        holdInc() {
            this.timerInc = setTimeout(() => {
                this.intervalInc = setInterval(() => {
                    this.inc();
                }, 150);
            }, 500);
        },
    },
};
