import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueInstall from './vueInstall';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

window.ENV = {
    enableOrders: true,
    wppNumber: '5551993395523',
};

Vue.config.productionTip = false;

Vue.use(VueInstall);
router.afterEach((to) => {
    /* eslint-disable camelcase */
    gtag('config', window.GA_TRACKING_ID, {
        page_path: to.fullPath,
        app_name: 'Monitive Web App',
        send_page_view: true,
    });
    /* eslint-enable camelcase */
});
new Vue({
    router,
    render: (handle) => handle(App),
}).$mount('#app');
