import categories from '../data/categories.json';
import cakes from '../data/cakes.json';
import savories from '../data/savories.json';
import combos from '../data/combos.json';
import deserts from '../data/deserts.json';
import sweets from '../data/sweets.json';
import Category from '../assets/js/Category';
import Product from '../assets/js/Product';

class DataController {
    constructor() {
        this.categories = this.importCategories(categories);
        this.cakes = this.importProducts(cakes);
        this.savories = this.importProducts(savories);
        this.sweets = this.importProducts(sweets);
        this.combos = this.importProducts(combos);
        this.deserts = this.importProducts(deserts);
    }

    importCategories(categories) {
        return categories.map((categ) => new Category(categ));
    }

    importProducts(prods) {
        return prods.map((prod) => {
            const category = this.getCategoriesById(prod.category);
            prod.category = category;
            return new Product(prod);
        });
    }

    getCategoriesByType(type) {
        return this.categories.filter((categ) => {
            return categ.type === type.id;
        });
    }

    getCategoriesById(id) {
        return this.categories.find((categ) => {
            return categ.id === id;
        });
    }

    getProductsByType(type) {
        return this[type.desc] ? this[type.desc] : [];
    }
}

export default new DataController();
