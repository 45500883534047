import Order from '../assets/js/Order';
import filters from '../directives/filters';

class OrderController {
    constructor() {
        this.order = new Order();
    }

    get total() {
        return this.order.total;
    }

    get count() {
        return this.order.items.length;
    }

    get productList() {
        return this.order.items.sort((first, sec) => first.product.category.id - sec.product.category.id);
    }

    hasCakes() {
        return this.order.items.some((item) => item.product.category.type === 3);
    }

    isOnlyCakes() {
        return this.order.items.every((item) => item.product.category.type === 3);
    }

    addItem(product, flavor, shape, slices) {
        return this.order.add({ product, flavor, shape, slices });
    }

    addOptionItem(product, options) {
        return this.order.addOptionItem({ product, options });
    }

    removeItem(id) {
        return this.order.removeItem(id);
    }

    getItem(prod, flavor, shape, slices) {
        return this.order.get(prod, flavor, shape, slices);
    }

    getItems(prod) {
        return this.order.items.filter((item) => {
            const byId = item.product.key === prod.key;
            const byFlavor = prod.flavor ? item.flavor : true;
            const byShape = prod.shape ? item.shape : true;
            const byOptions = prod.options ? item.options : true;
            return byId && byFlavor && byShape && byOptions;
        });
    }

    remove(prod) {
        this.order.remove(prod);
    }

    inc(prod) {
        this.order.inc(prod);
    }

    dec(prod) {
        this.order.dec(prod);
    }

    clear() {
        this.order.clear();
    }

    mountText(orderDate, period, name) {
        let message = `Pedido realizado pelo site!%0ANome: ${name}%0ARetirada: ${filters.date(orderDate)} - ${period}%0A%0A`;
        this.productList.forEach((item) => {
            message += `${item.qty}${item.product.unity} ${item.fullName}`;
            if (item.options) {
                message += ` (${item.options.map((opt) => opt.desc).join(', ')})`;
            }
            message += ` - ${filters.currency(item.total)}%0A`;
        });
        message += `Total: ${filters.currency(this.total)}`;
        return message;
    }
}

export default new OrderController();
