import Item from './Item';

class Order {
    constructor() {
        this.items = [];
    }

    get total() {
        return this.items.reduce((acc, item) => acc + item.total, 0);
    }

    addOrUpdate(props) {
        this.items.push(new Item(props));
    }

    remove(index) {
        if (index > this.items.length) {
            return;
        }
        this.items.splice(index, 1);
    }

    removeItem(id) {
        const index = this.items.findIndex((i) => i.id === id);
        this.items.splice(index, 1);
    }

    add({ product, flavor, shape, slices, options }) {
        let item = this.get(product, flavor, shape, slices);
        if (!item) {
            item = new Item({ product, flavor, shape, slices, options });
            this.items.push(item);
        }
        return item;
    }

    addOptionItem({ product, options }) {
        const item = new Item({ product, options });
        item.qty = 1;
        this.items.push(item);
        return item;
    }

    get(product, flavor, shape, slices) {
        if (flavor) {
            return this.items.find((i) => i.product.key === product.key && i.flavor.id === flavor.id);
        }
        if (shape && slices) {
            return this.items.find((i) => i.product.key === product.key && i.shape === shape && i.slices === slices);
        }
        return this.items.find((i) => i.product.key === product.key);
    }

    clear() {
        this.items.splice(0, this.items.length);
    }

    toString() {
        return 'Ordem de teste';
    }
}

export default Order;
