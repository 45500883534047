import ProductDesc from './ProductDesc';
import template from './ProductList.html';

export default {
    template,
    props: ['products', 'type', 'shape', 'slices', 'shapeName'],
    components: {
        ProductDesc,
    },
};
