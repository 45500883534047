import ProductType from '../assets/js/ProductType';
import ProductPageMixin from './ProductPageMixin';

export default {
    name: 'combos',
    mixins: [ProductPageMixin],
    data() {
        return {
            type: ProductType.COMBOS,
        };
    },
};
