import template from './ProductActions.html';

export default {
    template,
    props: ['orderStatus'],
    data() {
        const that = this;
        return {
            buttons: [
                {
                    key: 'show',
                    event: 'show',
                    title: 'Mostrar opções',
                    get visible() {
                        return that.orderStatus === 0;
                    },
                },
            ],
        };
    },
};
