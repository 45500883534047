import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Sweets from './pages/Sweets.js';
import Savories from './pages/Savories.js';
import Cakes from './pages/Cakes.js';
import Combos from './pages/Combos.js';
import Deserts from './pages/Deserts.js';
import Contact from './pages/Contact.vue';
import Gallery from './pages/Gallery.vue';
import Checkout from './pages/Checkout.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);
const header = MainNavbar;
const footer = MainFooter;
const props = { header: { colorOnScroll: 400 }, footer: { backgroundColor: 'black' } };

export default new Router({
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'index',
            props,
            components: { header, footer, default: Index },
        },
        {
            path: '/doces',
            name: 'sweets',
            props,
            components: { header, footer, default: Sweets },
        },
        {
            path: '/salgados',
            name: 'savories',
            props,
            components: { header, footer, default: Savories },
        },
        {
            path: '/tortas',
            name: 'cakes',
            props,
            components: { header, footer, default: Cakes },
        },
        {
            path: '/combos',
            name: 'combos',
            props,
            components: { header, footer, default: Combos },
        },
        {
            path: '/sobremesas',
            name: 'deserts',
            props,
            components: { header, footer, default: Deserts },
        },
        {
            path: '/contato',
            name: 'contact',
            props,
            components: { header, footer, default: Contact },
        },
        {
            path: '/galeria',
            name: 'gallery',
            props,
            components: { header, footer, default: Gallery },
        },
        {
            path: '/pedido',
            name: 'checkout',
            props,
            components: { header, footer, default: Checkout },
        },
    ],
    scrollBehavior: (to) => {
        return to.hash ? { selector: to.hash } : { x: 0, y: 0 };
    },
});
