import ProductType from '../../assets/js/ProductType';
import template from './ProductForm.html';
import ProductInput from './ProductInput';
import OptionInput from './OptionInput';
import filters from '@/directives/filters';
import OrderController from '../../controllers/OrderController';

export default {
    filters,
    template,
    props: ['product', 'shape', 'slices', 'flavor'],
    components: { ProductInput, OptionInput },
    computed: {
        productTotal() {
            const products = OrderController.getItems(this.product);
            return products && products.reduce((acc, item) => acc + item.total, 0);
        },
    },
    methods: {},
};
