import ProductList from './components/ProductList';
import DataController from '../controllers/DataController';
import template from './ProductPage.html';

export default {
    template,
    bodyClass: 'profile-page',
    data() {
        return {
            type: null,
            products: null,
            categories: null,
            hasSelectors: false,
        };
    },
    mounted() {
        this.products = DataController.getProductsByType(this.type);
        this.categories = DataController.getCategoriesByType(this.type);
    },
    methods: {
        getProductsByCategory(category) {
            return this.products.filter((product) => product.category.id === category.id);
        },
    },
    components: {
        ProductList,
    },
};
