import ProductType from '../assets/js/ProductType';
import ProductPageMixin from './ProductPageMixin';

export default {
    name: 'cakes',
    mixins: [ProductPageMixin],
    data() {
        return {
            type: ProductType.CAKES,
            hasSelectors: true,
        };
    },
    methods: {
        countShapes(shapes) {
            return Object.entries(shapes).length;
        },
        selectShape(group, shape) {
            group.selectedShape = Number(shape);
            this.selectSlices(group, group.shapes[shape].slices[0]);
        },
        selectSlices(group, slices) {
            group.selectedSlices = Number(slices);
        },
    },
};
