import OrderController from '../../controllers/OrderController';
import template from './OptionInput.html';

export default {
    template,
    props: ['product'],
    data() {
        return {
            selectedOptions: [],
            items: [],
        };
    },
    computed: {
        maxOpt() {
            return this.product.options.maxOpt;
        },
    },
    mounted() {
        this.updateItems();
    },
    methods: {
        confirmSelectedList() {
            OrderController.addOptionItem(this.product, [...this.selectedOptions]);
            this.updateItems();
            this.clearSelectedList();
        },
        clearSelectedList() {
            this.selectedOptions.splice(0, this.selectedOptions.length);
        },
        addOption(option) {
            if (this.selectedOptions.length >= this.maxOpt) {
                this.selectedOptions.shift();
            }
            this.selectedOptions.push({ ...option });
        },
        removeOption(i) {
            OrderController.removeItem(this.product, i);
            this.updateItems();
        },
        updateItems() {
            this.items = OrderController.getItems(this.product);
        },
    },
};
