class Options {
    constructor(options, maxOpt) {
        this.maxOpt = maxOpt;
        this.options = this.processOptions(options);
    }

    get all() {
        return this.options;
    }

    get desc() {
        return this.options.reduce(this.joinDesc, '');
    }

    get descSelected() {
        return this.options.filter((option) => option.selected).reduce(this.joinDesc, '');
    }

    get hasValidSelected() {
        return this.options.filter((option) => option.selected).length === this.maxOpt;
    }

    joinDesc(prev, cur) {
        return prev ? `${prev} | ${cur.desc}` : `${cur.desc}`;
    }

    clear() {
        this.options.forEach((option) => {
            option.selected = false;
        });
    }

    processOptions(data) {
        let idCount = 0;
        return data.map((desc) => {
            return { id: idCount++, desc, selected: false };
        });
    }
}

export default Options;
