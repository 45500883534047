<template>
    <div id="app">
        <router-view name="header" />
        <div class="wrapper">
            <router-view />
        </div>
        <router-view name="footer" />
        <a href="https://api.whatsapp.com/send?phone=5551993395523" target="_blank">
            <img id="whatsapp-icon" src="img/whatsappIcon.png" />
        </a>
        <div class="badge-container cart-bottom" v-if="counter > 0">
            <router-link :to="{ name: 'checkout' }" class="nav-link">
                <i class="fas fa-shopping-cart"></i>
                <span class="badge badge-info">{{ counter }}</span>
            </router-link>
        </div>
    </div>
</template>
<script>
import OrderController from './controllers/OrderController';
export default {
    data() {
        return {
            OrderController,
        };
    },
    computed: {
        counter() {
            return this.OrderController.count;
        },
    },
};
</script>
<style scoped>
.cart-bottom {
    position: fixed;
    right: 35px;
    bottom: 90px;
    cursor: pointer;
}
.cart-bottom:hover {
    right: 33px;
    bottom: 88px;
}
.cart-bottom a i {
    font-size: 35px;
    color: #ffffff;
}
.cart-bottom:hover a i {
    font-size: 39px !important;
}
.cart-bottom .badge-info {
    color: var(--main-bg-color);
    line-height: 7px;
    font-size: 1em;
    padding: 4px;
    position: absolute;
    top: 2px;
    left: 48px;
}
.cart-bottom:hover .badge-info {
    top: 4px;
    left: 52px;
}
</style>
