export default {
    currency: function (number) {
        if (isNaN(number)) {
            return '-';
        }
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
    },
    date: function (date) {
        if (!(date instanceof Date)) {
            return '-';
        }
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    },
};
